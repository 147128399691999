.FileList {
    overflow: auto
}

.FileList .File .filename > span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

@media (min-width : 600px) {
    .FileList .File {
        float: left;
        width: 33.3%;
    }
}
@media (min-width : 1024px) {
    .FileList .File {
        float: left;
        width: 25%;
    }
}