@import url(https://fonts.googleapis.com/css?family=Roboto:300);
.File {
    cursor: pointer;
    float: left;
    display: block;
    width: 100%;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.File:hover {
    cursor: pointer;
    background: #fafafa;
}

.File[data-selected=true] {
    background-color: #e8f0fe;
    
}

.File[data-selected=true],
.File[data-selected=true] > li > div > span {
    color: #1967d2;
}
.FileListEmptyMessage {
    margin: 5px 10px;
    padding: 20px;
    display: block;
    border-radius: 5px;
    background: #efefef;
    color: #333;
    font-size: 15px;
}
.FileList {
    overflow: auto
}

.FileList .File .filename > span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

@media (min-width : 600px) {
    .FileList .File {
        float: left;
        width: 33.3%;
    }
}
@media (min-width : 1024px) {
    .FileList .File {
        float: left;
        width: 25%;
    }
}
.BreadcrumbText {
}

.BreadcrumbText span {
    cursor: pointer;
    text-overflow: ellipsis;
}

.BreadcrumbText span:hover {
    color: #efefef;
}
.XXXXXXXXXXXContextMenu {
    max-width: 200px;
    position: absolute;
    display: block;
    background: green;
    border: 1px solid black;
    padding: 10px;
    text-align: left;
}

.ContextMenu ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.ContextMenu ul li {
    padding: 10px 20px;
    border-bottom: 1px solid #000;
}

.ContextMenu ul li:last-child {
    border: none;
}
.FileListSublist {
    overflow: auto;
    max-height: 20em;
}
.login-page {
  width: 360px;
  padding: 8% 0 0;
  margin: auto;
}
.form {
  position: relative;
  z-index: 1;
  background: #ffffff;
  max-width: 360px;
  margin: 0 auto 100px;
  padding: 45px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}
.input {
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}
.submit {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #0c205f;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #ffffff;
  font-size: 14px;
  transition: all 0.3 ease;
  cursor: pointer;
}
.submit:hover,
.submit:active,
.submit:focus {
  background: #181bb3;
}
.form .message {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 12px;
}
.form .message a {
  color: #0c205f;
  text-decoration: none;
}
.form .register-form {
  display: none;
}
.container {
  position: relative;
  z-index: 1;
  max-width: 300px;
  margin: 0 auto;
}
.container:before,
.container:after {
  content: "";
  display: block;
  clear: both;
}
.container .info {
  margin: 50px auto;
  text-align: center;
}
.container .info h1 {
  margin: 0 0 15px;
  padding: 0;
  font-size: 36px;
  font-weight: 300;
  color: #1a1a1a;
}
.container .info span {
  color: #4d4d4d;
  font-size: 12px;
}
.container .info span a {
  color: #000000;
  text-decoration: none;
}
.container .info span .fa {
  color: #ef3b3a;
}
.connexion {
  padding-bottom: 2vh;
}

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

*::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 0;
    background-color: #F5F5F5;
    cursor: default;
}

*::-webkit-scrollbar {
    width: 8px;
    background-color: #F5F5F5;
    cursor: default;
}

*::-webkit-scrollbar-thumb {
    border-radius: 0px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #555;
    cursor: default;
}
