.BreadcrumbText {
}

.BreadcrumbText span {
    cursor: pointer;
    text-overflow: ellipsis;
}

.BreadcrumbText span:hover {
    color: #efefef;
}