.File {
    cursor: pointer;
    float: left;
    display: block;
    width: 100%;
    user-select: none;
}

.File:hover {
    cursor: pointer;
    background: #fafafa;
}

.File[data-selected=true] {
    background-color: #e8f0fe;
    
}

.File[data-selected=true],
.File[data-selected=true] > li > div > span {
    color: #1967d2;
}