.XXXXXXXXXXXContextMenu {
    max-width: 200px;
    position: absolute;
    display: block;
    background: green;
    border: 1px solid black;
    padding: 10px;
    text-align: left;
}

.ContextMenu ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.ContextMenu ul li {
    padding: 10px 20px;
    border-bottom: 1px solid #000;
}

.ContextMenu ul li:last-child {
    border: none;
}